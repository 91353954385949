<template>
  <div class="page cashback-page">
    <div class="cashback-page__title" :class="{'locale-usa': locale ==='USA'}">
      <Title v-if="locale !=='USA'" :text="!activeAccount ? $t('cashback_page.terms') : $t('cashback_page.reward_fee')" />
    </div>
    <div v-if="!activeAccount && locale !=='USA'" class="cashback-page__table-box">
      <div class="cashback-page__table">
      <div class="cashback-page__table-title">{{ $t('cashback_page.reward') }}</div>
        <el-table v-if="getLang === 'ru'"
          :data="rewardTableData"
          style="width: 100%"
        >
          <el-table-column width="240">
            <template slot="header">
              {{ $t('cashback_page.turnover_no_vat') }}
            </template>
            <template slot-scope="scope">
              <div class="cell">
                {{ scope.row.turnover }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="190">
            <template slot="header">
              <div class="cashback-page__table-thead">
                <img src="../assets/icons/icon-vkontacte.svg" alt="">
                vkontakte
              </div>
            </template>
            <template slot-scope="scope">
              <span class="cashback-page__table-percent">
                {{ scope.row.vk }}
              </span>
            </template>
          </el-table-column>
          <el-table-column width="190">
            <template slot="header">
              <div class="cashback-page__table-thead">
                <img src="../assets/icons/icon-mytarget.svg" alt="">
                myTarget
              </div>
            </template>
            <template slot-scope="scope">
              <span class="cashback-page__table-percent">
                {{ scope.row.mytarget }}
              </span>
            </template>
          </el-table-column>
          <el-table-column width="190">
            <template slot="header">
              <div class="cashback-page__table-thead">
                <img src="../assets/icons/yandex-direct-icon.svg" alt="">
                yandex
              </div>
            </template>
            <template slot-scope="scope">
              <span class="cashback-page__table-percent">
                {{ scope.row.yandex }}
              </span>
            </template>
          </el-table-column>
          <el-table-column v-if="!activeAccount"></el-table-column>
        </el-table>
        <el-table v-if="getLang === 'en'"
          :data="rewardTableDataEn"
          style="width: 100%"
        >
          <el-table-column width="240">
            <template slot="header">
              {{ $t('cashback_page.turnover_no_vat') }}
            </template>
            <template slot-scope="scope">
              <div class="cell">
                {{ scope.row.turnover }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="190">
            <template slot="header">
              <div class="cashback-page__table-thead">
                <img src="../assets/icons/icon-vkontacte.svg" alt="">
                vkontakte
              </div>
            </template>
            <template slot-scope="scope">
              <span class="cashback-page__table-percent">
                {{ scope.row.vk }}
              </span>
            </template>
          </el-table-column>
          <el-table-column width="190">
            <template slot="header">
              <div class="cashback-page__table-thead">
                <img src="../assets/icons/icon-mytarget.svg" alt="">
                myTarget
              </div>
            </template>
            <template slot-scope="scope">
              <span class="cashback-page__table-percent">
                {{ scope.row.mytarget }}
              </span>
            </template>
          </el-table-column>
          <el-table-column width="190">
            <template slot="header">
              <div class="cashback-page__table-thead">
                <img src="../assets/icons/yandex-direct-icon.svg" alt="">
                yandex
              </div>
            </template>
            <template slot-scope="scope">
              <span class="cashback-page__table-percent">
                {{ scope.row.yandex }}
              </span>
            </template>
          </el-table-column>
          <el-table-column v-if="!activeAccount"></el-table-column>
        </el-table>
      </div>
      <div class="cashback-page__table commission-table">
        <div class="cashback-page__table-title">{{ $t('cashback_page.comission') }}</div>
        <el-table
          v-if="getLang==='ru'"
          :data="commissionTableData"
          style="width: 100%"
        >
          <el-table-column width="240">
            <template slot="header">
              {{ $t('cashback_page.turnover_no_vat') }}
            </template>
            <template slot-scope="scope">
              <div class="cell">
                {{ scope.row.turnover }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="190">
            <template slot="header">
              <div class="cashback-page__table-thead">
                <img src="../assets/icons/icon-google.svg" alt="">
                google
              </div>
            </template>
            <template slot-scope="scope">
              <span class="cashback-page__table-percent">
                {{ scope.row.google }}
              </span>
            </template>
          </el-table-column>
          <el-table-column>
          </el-table-column>
          <el-table-column>
          </el-table-column>
        </el-table>
        <el-table
          v-if="getLang==='en'"
          :data="commissionTableDataEn"
          style="width: 100%"
        >
          <el-table-column width="240">
            <template slot="header">
              {{ $t('cashback_page.turnover_no_vat') }}
            </template>
            <template slot-scope="scope">
              <div class="cell">
                {{ scope.row.turnover }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="190">
            <template slot="header">
              <div class="cashback-page__table-thead">
                <img src="../assets/icons/icon-google.svg" alt="">
                google
              </div>
            </template>
            <template slot-scope="scope">
              <span class="cashback-page__table-percent">
                {{ scope.row.google }}
              </span>
            </template>
          </el-table-column>
          <el-table-column>
          </el-table-column>
          <el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </div>
    
    <div v-if="activeAccount">
      <div class="cashback-page__tabs">
        <div
          v-if="activeAccount?.visible_services?.vkads || (role ==='partner' && activeAccount?.services?.includes('vkads'))"
          class="cashback-page__tabs-tab"
          :class="{active: chosenPlatform === 'vkads', 'locale-usa': locale === 'USA'}"
          @click="changeActivePlatform('vkads')"
        >
          <img src="../assets/icons/icon-vkreklama.svg" alt="">
          {{locale==='RU'?'VK Реклама':'VK Ads'}}
        </div>
        <div
          v-if="(activeAccount?.visible_services?.mytarget || (role ==='partner' && activeAccount?.services?.includes('mytarget'))) && locale==='RU'"
          class="cashback-page__tabs-tab"
          :class="{active: chosenPlatform === 'mytarget', 'locale-usa': locale === 'USA'}"
          @click="changeActivePlatform('mytarget')"
        >
          <img src="../assets/icons/icon-mytarget.svg" alt="mytarget logo">
          myTarget
        </div>
        <div
          v-if="(activeAccount?.visible_services?.vkontakte || (role ==='partner' && activeAccount?.services?.includes('vkontakte')) ) && locale==='RU'"
          class="cashback-page__tabs-tab"
          :class="{active: chosenPlatform === 'vkontakte', 'locale-usa': locale === 'USA'}"
          @click="changeActivePlatform('vkontakte')"
        >
          <img src="../assets/icons/icon-vkontacte.svg" alt="">
          {{locale==='RU'?'VКонтакте':'VKontakte'}}
        </div>
        <div
            v-if="activeAccount?.visible_services?.yandex || (role ==='partner' && activeAccount?.services?.includes('yandex'))"
            class="cashback-page__tabs-tab"
            :class="{active: chosenPlatform === 'yandex', 'locale-usa': locale === 'USA'}"
            @click="changeActivePlatform('yandex')"
        >
          <img src="../assets/icons/yandex-direct-icon.svg" alt="">
          {{locale==='RU'?'Яндекс Директ':'Yandex.Direct'}}
        </div>
        <div
            v-if="activeAccount?.visible_services?.yandexbusiness || (role ==='partner' && activeAccount?.services?.includes('yandexbusiness'))"
            class="cashback-page__tabs-tab"
            :class="{active: chosenPlatform === 'yandexbusiness', 'locale-usa': locale === 'USA'}"
            @click="changeActivePlatform('yandexbusiness')"
        >
          <img src="../assets/icons/icon-yandex-business.png" alt="">
          {{locale==='RU'?'Яндекс Бизнес':'Yandex.Business'}}
        </div>
        <div
          v-if="activeAccount?.visible_services?.google || (role ==='partner' && activeAccount?.services?.includes('google'))"
          class="cashback-page__tabs-tab"
          :class="{active: chosenPlatform === 'google', 'locale-usa': locale === 'USA'}"
          @click="changeActivePlatform('google')"
        >
          <img src="../assets/icons/icon-google.svg" alt="">
          Google Ads
        </div>
        <div
            v-if="activeAccount?.visible_services?.facebook || (role ==='partner' && activeAccount?.services?.includes('facebook'))"
            class="cashback-page__tabs-tab"
            :class="{active: chosenPlatform === 'facebook', 'locale-usa': locale === 'USA'}"
            @click="changeActivePlatform('facebook')"
        >
          <img src="../assets/icons/icon-meta.svg" alt="">
          Meta
        </div>
        <div
            v-if="activeAccount?.visible_services?.tiktok || (role ==='partner' && activeAccount?.services?.includes('tiktok'))"
            class="cashback-page__tabs-tab"
            :class="{active: chosenPlatform === 'tiktok', 'locale-usa': locale === 'USA'}"
            @click="changeActivePlatform('tiktok')"
        >
          <img src="../assets/icons/icon-tiktok.svg" alt="">
          Tiktok
        </div>
        <div
            v-if="(activeAccount?.visible_services?.telegram || (role ==='partner' && activeAccount?.services?.includes('telegram'))) && !(locale==='USA' && !activeAccount.is_legal)"
            class="cashback-page__tabs-tab"
            :class="{active: chosenPlatform === 'telegram', 'locale-usa': locale === 'USA'}"
            @click="changeActivePlatform('telegram')"
        >
          <img src="../assets/icons/icon-telegram.svg" alt="">
          Telegram
        </div>
      </div>
      
      <div class="cashback-page__content">
        <CashbackCart
          v-show="chosenPlatform === 'yandex'"
          :title="$t('cashback_cart_props.title[0]')"
          :description="$t('cashback_cart_props.description[0]')"
          :balance="activeAccount.services ? activeAccount?.services?.yandex?.precalc?.cashback : null"
          :conditions="cashback.advertising.yandex"
          :editable="editable"
          type="advertising"
          platform="yandex"
          @change="changeConditions"
        />
        <CashbackCart
          v-show="chosenPlatform === 'yandexbusiness'"
          :title="$t('cashback_cart_props.title[0]')"
          :description="$t('cashback_cart_props.description[0]')"
          :balance="activeAccount.services ? activeAccount?.services?.yandexbusiness?.precalc?.cashback : null"
          :conditions="cashback.advertising.yandexbusiness"
          :editable="editable"
          type="advertising"
          platform="yandexbusiness"
          @change="changeConditions"
        />
        <CashbackCart
          v-show="chosenPlatform === 'mytarget'"
          :title="$t('cashback_cart_props.title[0]')"
          :description="$t('cashback_cart_props.description[0]')"
          :balance="activeAccount.services ? activeAccount?.services?.mytarget?.precalc?.cashback : null"
          :conditions="cashback.advertising.mytarget"
          :editable="editable"
          type="advertising"
          platform="mytarget"
          @change="changeConditions"
        />
        <CashbackCart
          v-show="chosenPlatform === 'vkontakte'"
          :title="$t('cashback_cart_props.title[0]')"
          :description="$t('cashback_cart_props.description[0]')"
          :balance="activeAccount.services ? activeAccount?.services?.vkontakte?.precalc?.cashback : null"
          :conditions="cashback.advertising.vkontakte"
          :editable="editable"
          type="advertising"
          platform="vkontakte"
          @change="changeConditions"
        />
        <CashbackCart
          v-show="chosenPlatform === 'vkads'"
          :title="$t('cashback_cart_props.title[0]')"
          :description="$t('cashback_cart_props.description[0]')"
          :balance="activeAccount.services ? activeAccount?.services?.vkads?.precalc?.cashback : null"
          :conditions="cashback.advertising.vkads"
          :editable="editable"
          type="advertising"
          platform="vkads"
          @change="changeConditions"
        />
        <CashbackCart
            v-show="chosenPlatform === 'google'"
            :title="$t('cashback_cart_props.title[0]')"
            :description="$t('cashback_cart_props.description[0]')"
            :balance="activeAccount.services ? activeAccount?.services?.google?.precalc?.cashback : null"
            :conditions="cashback.advertising.google"
            :editable="editable"
            type="advertising"
            platform="google"
            @change="changeConditions"
        />
        <CashbackCart
            v-show="chosenPlatform === 'facebook'"
            :title="$t('cashback_cart_props.title[0]')"
            :description="$t('cashback_cart_props.description[0]')"
            :balance="activeAccount.current_month_cashback ? activeAccount?.current_month_cashback?.for_advertising : 0"
            :conditions="cashback.advertising.facebook"
            :editable="editable"
            type="advertising"
            platform="facebook"
            @change="changeConditions"
        />
        <CashbackCart
            v-show="chosenPlatform === 'tiktok'"
            :title="$t('cashback_cart_props.title[0]')"
            :description="$t('cashback_cart_props.description[0]')"
            :balance="activeAccount.services ? activeAccount?.services?.tiktok?.precalc?.cashback : null"
            :conditions="cashback.advertising.tiktok"
            :editable="editable"
            type="advertising"
            platform="tiktok"
            @change="changeConditions"
        />
        <CashbackCart
            v-show="chosenPlatform === 'telegram'"
            :title="$t('cashback_cart_props.title[0]')"
            :description="$t('cashback_cart_props.description[0]')"
            :balance="activeAccount.services ? activeAccount?.services?.telegram?.precalc?.cashback : null"
            :conditions="cashback.advertising.telegram"
            :editable="editable"
            type="advertising"
            platform="telegram"
            @change="changeConditions"
        />
        <div
          v-if="(role === 'manager' || role === 'finance') && !editable"
          class="cashback-page__edit"
          @click="editable = true"
        >
        {{$t('cashback_views.edit')}}
        </div>
      </div>
      <div class="cashback-page__content cashback-page__content--red">
        <CashbackCart
            v-show="chosenPlatform === 'mytarget' && checkConditions(commissionConditions.mytarget)"
            :title="$t('cashback_cart_props.title[3]')"
            :conditions="commissionConditions.mytarget"
            :editable="editable"
            type="commission-terms"
            platform="mytarget"
            @change="changeCommissionConditions"
        />
        <CashbackCart
            v-show="chosenPlatform === 'vkontakte' && checkConditions(commissionConditions.vkontakte)"
            :title="$t('cashback_cart_props.title[3]')"
            :conditions="commissionConditions.vkontakte"
            :editable="editable"
            type="commission-terms"
            platform="vkontakte"
            @change="changeCommissionConditions"
        />
        <CashbackCart
            v-show="chosenPlatform === 'vkads' && checkConditions(commissionConditions.vkads)"
            :title="$t('cashback_cart_props.title[3]')"
            :conditions="commissionConditions.vkads"
            :editable="editable"
            type="commission-terms"
            platform="vkads"
            @change="changeCommissionConditions"
        />
        <CashbackCart
            v-show="chosenPlatform === 'facebook' && checkConditions(commissionConditions.facebook)"
            :title="$t('cashback_cart_props.title[3]')"
            :conditions="commissionConditions.facebook"
            :editable="editable"
            type="commission-terms"
            platform="facebook"
            @change="changeCommissionConditions"
        />
        <CashbackCart
            v-show="chosenPlatform === 'yandex' && checkConditions(commissionConditions.yandex)"
            :title="$t('cashback_cart_props.title[3]')"
            :conditions="commissionConditions.yandex"
            :editable="editable"
            type="commission-terms"
            platform="yandex"
            @change="changeCommissionConditions"
        />
        <CashbackCart
            v-show="chosenPlatform === 'yandexbusiness' && checkConditions(commissionConditions.yandexbusiness)"
            :title="$t('cashback_cart_props.title[3]')"
            :conditions="commissionConditions.yandexbusiness"
            :editable="editable"
            type="commission-terms"
            platform="yandexbusiness"
            @change="changeCommissionConditions"
        />
        <CashbackCart
            v-show="chosenPlatform === 'google' && checkConditions(commissionConditions.google)"
            :title="$t('cashback_cart_props.title[3]')"
            :conditions="commissionConditions.google"
            :editable="editable"
            type="commission-terms"
            platform="google"
            @change="changeCommissionConditions"
        />
        <CashbackCart
            v-show="chosenPlatform === 'tiktok' && checkConditions(commissionConditions.tiktok)"
            :title="$t('cashback_cart_props.title[3]')"
            :conditions="commissionConditions.tiktok"
            :editable="editable"
            type="commission-terms"
            platform="tiktok"
            @change="changeCommissionConditions"
        />
        <CashbackCart
            v-show="chosenPlatform === 'telegram' && checkConditions(commissionConditions.telegram)"
            :title="$t('cashback_cart_props.title[3]')"
            :conditions="commissionConditions.telegram"
            :editable="editable"
            type="commission-terms"
            platform="telegram"
            @change="changeCommissionConditions"
        />
        <div
          v-if="(role === 'manager' || role === 'finance') && !editable"
          class="cashback-page__edit"
          @click="editable = true"
        >
          {{$t('cashback_views.edit')}}
        </div>
      </div>
      <div v-if="errors" class="cashback-page__errors">
        <p v-for="(error, idx) in errors" :key="idx">
          <span v-for="(err, idx2) in error" :key="idx2">
            {{ err }}
          </span>
        </p>
      </div>
      <div v-if="editable" class="cashback-page__buttons">
        <button @click="saveChanges" class="btn btn-save">{{$t('button_props.save_changes')}}</button>
        <button @click="cancelChanges" class="btn btn-cancel">{{$t('button_props.cancel')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "../components/elements/Title";
import CashbackCart from "../components/cashback/CashbackCart";
import checkCashback from '@/mixin/checkCashback'

export default {
  name: 'Cashback',
  components: {CashbackCart, Title},
  mixins: [checkCashback],
  data() {
    return {
      locale: process.env.VUE_APP_LOCALE,
      lang: this.$i18n._vm.locale,
      editable: false,
      chosenPlatform: '',
      totalConditions: null,
      totalCommissionConditions: null,
      errors: null,

      rewardTableData: [
        {
          turnover: 'от 30 000 до 299 999',
          vk: '6%',
          mytarget: '6%', 
          yandex: '4%',
          yandexbusiness: '4%',
        },
        {
          turnover: 'от 300 000 до 699 999',
          vk: '8%',
          mytarget: '8%', 
          yandex: '5%',
          yandexbusiness: '5%',
        },
        {
          turnover: 'от 700 000 до 1 999 999',
          vk: '10%',
          mytarget: '10%', 
          yandex: '6%',
          yandexbusiness: '6%',
        },
        {
          turnover: 'от 2 000 000,00 до 3 999 999',
          vk: '11%',
          mytarget: '11%', 
          yandex: '6.5%',
          yandexbusiness: '6.5%',
        },
        {
          turnover: 'от 4 000 000',
          vk: '13%',
          mytarget: '13%', 
          yandex: '7%',
          yandexbusiness: '7%',
        }
      ],
      rewardTableDataEn: [
        {
          turnover: 'from 30 000 to 299 999',
          vk: '6%',
          mytarget: '6%', 
          yandex: '4%',
          yandexbusiness: '4%',
        },
        {
          turnover: 'from 300 000 to 699 999',
          vk: '8%',
          mytarget: '8%', 
          yandex: '5%',
          yandexbusiness: '5%',
        },
        {
          turnover: 'from 700 000 to 1 999 999',
          vk: '10%',
          mytarget: '10%', 
          yandex: '6%',
          yandexbusiness: '6%',
        },
        {
          turnover: 'from 2 000 000,00 to 3 999 999',
          vk: '11%',
          mytarget: '11%', 
          yandex: '6.5%',
          yandexbusiness: '6.5%',
        },
        {
          turnover: 'from 4 000 000',
          vk: '13%',
          mytarget: '13%', 
          yandex: '7%',
          yandexbusiness: '7%',
        }
      ],

      commissionTableData: [
        {
          turnover: 'от 0 до 1 999 999',
          google: '5%',
        },
        {
          turnover: 'от 2 000 00',
          google: '4%',
        },
      ],
      commissionTableDataEn: [
        {
          turnover: 'from 0 to 1 999 999',
          google: '5%',
        },
        {
          turnover: 'from 2 000 00',
          google: '4%',
        },
      ]
    }
  },
  computed: {
    getLang() {
      let language = this.$i18n._vm.locale
      return language
    },
    activeAccount() {
      return this.$store.state.Auth.activeAccount
    },
    cashback() {
      if(this.activeAccount) {
        return this.activeAccount.cashback_conditions
      }
      return {}
    },
    commissionConditions() {
      let obj = {}
      if (this.activeAccount && this.activeAccount.commissions_conditions) {
        for (const key in this.activeAccount.commissions_conditions) {
          if (!Array.isArray(this.activeAccount.commissions_conditions[key])) {
            obj[key] = [{ max: null, min: 0, percent: 0 }]
          } else {
            obj[key] = this.activeAccount.commissions_conditions[key]
          }
        }
        if(!('telegram' in obj) && this.locale === 'RU') {
          obj.telegram = [{ max: null, min: 0, percent: 15 }]
        }
      }
      return obj
    },
    role() {
      return this.$store.state.Auth.role
    },
  },
  watch: {
    showCashback(val) {
      if (!val) {
        this.$router.push('/statistics')
      }
    },
    activeAccount(val) {
      if(val) {
        this.totalConditions = JSON.parse(JSON.stringify(val.cashback_conditions))
        this.totalCommissionConditions = JSON.parse(JSON.stringify(val.commissions_conditions))
        this.setStartActiveTab()
      }
    }
  },
  mounted() {
    if (this.cashback) {
      this.totalConditions = JSON.parse(JSON.stringify(this.cashback))
    }
    if (this.commissionConditions) {
      this.totalCommissionConditions = JSON.parse(JSON.stringify(this.commissionConditions))
    }
    if (this.activeAccount) {
      this.setStartActiveTab()
    }
  },
  methods: {
    setStartActiveTab() {
      if ((this.activeAccount.visible_services && this.activeAccount.visible_services.google) || (this.role ==='partner' && this.activeAccount?.services?.includes('google'))) {
        this.chosenPlatform = 'google';
      } else if ((this.activeAccount.visible_services && this.activeAccount.visible_services.vkads) || (this.role ==='partner' && this.activeAccount?.services?.includes('vkads'))) {
        this.chosenPlatform = 'vkads';
      } else if ((this.activeAccount.visible_services && this.activeAccount.visible_services.facebook) || (this.role ==='partner' && this.activeAccount?.services?.includes('facebook'))) {
        this.chosenPlatform = 'facebook';
      } else if ((this.activeAccount.visible_services && this.activeAccount.visible_services.yandex) || (this.role ==='partner' && this.activeAccount?.services?.includes('yandex'))) {
        this.chosenPlatform = 'yandex';
      } else if ((this.activeAccount.visible_services && this.activeAccount.visible_services.vkontakte) || (this.role ==='partner' && this.activeAccount?.services?.includes('vkontakte'))) {
        this.chosenPlatform = 'vkontakte';
      } else if ((this.activeAccount.visible_services && this.activeAccount.visible_services.mytarget) || (this.role ==='partner' && this.activeAccount?.services?.includes('mytarget'))) {
        this.chosenPlatform = 'mytarget';
      } else if ((this.activeAccount.visible_services && this.activeAccount.visible_services.tiktok) || (this.role ==='partner' && this.activeAccount?.services?.includes('tiktok'))) {
        this.chosenPlatform = 'tiktok';
      } else if ((this.activeAccount.visible_services && this.activeAccount.visible_services.telegram) || (this.role ==='partner' && this.activeAccount?.services?.includes('telegram'))) {
        this.chosenPlatform = 'telegram';
      } else if ((this.activeAccount.visible_services && this.activeAccount.visible_services.yandexbusiness) || (this.role ==='partner' && this.activeAccount?.services?.includes('yandexbusiness'))) {
        this.chosenPlatform = 'yandexbusiness';
      }
    },

    changeConditions(data, type, platform) {
      if (type === 'deposit') {
        this.totalConditions.deposit = data
      } else {
        if (!this.totalConditions[type])  this.totalConditions[type] = {}
        this.totalConditions[type][platform] = data
      }
    },
    changeCommissionConditions(data, type, platform) {
      if (!this.totalCommissionConditions) this.totalCommissionConditions = []
      this.totalCommissionConditions[platform] = data
    },
    changeActivePlatform(platform) {
      this.chosenPlatform = platform
    },
    cancelChanges() {
      this.errors = null
      this.editable = false
    },
    saveChanges() {
      const token = localStorage.getItem('token')
      if (this.totalConditions && Object.keys(this.totalConditions).length) {
        const data = {
          cashback_conditions: this.totalConditions,
          commissions_conditions: this.totalCommissionConditions
        }
        this.$store.dispatch('Balance/changeCashback', { id: this.activeAccount.id, data, token })
          .then(() => {
            window.location.reload()
            this.cancelChanges()
          })
          .catch((err) => {
            if (err.data.errors) this.errors = err.data.errors
          })

      }
      if (this.totalCommissionConditions && Object.keys(this.totalCommissionConditions).length) {
        const dataCommissions = {
          commissions_conditions: this.totalCommissionConditions
        }
        this.$store.dispatch('Balance/changeCommission', { id: this.activeAccount.id, data: dataCommissions, token })
            .then(() => {
              this.cancelChanges()
            })
            .catch((err) => {
              if (err.data.errors) this.errors = err.data.errors
            })
      }
    },
    checkConditions(platform) {
      if (this.role === 'manager' || this.role === 'finance') {
        return true
      } else if (platform){
        return !!platform.find(el => {
          return +el.percent !== 0
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.cashback-page {
  &__title {
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    &.locale-usa {
      margin-bottom: 3.2rem;
      .title {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 700;
        font-size: 3.2rem;
      }
    }
  }

  &__table {
    margin-bottom: 3.2rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    &-box {
      padding: 3.2rem;
      background: #fff;
      box-shadow: 0px 4px 12px rgba(192, 192, 192, 0.12);
      border-radius: 6px;
      margin-bottom: 3rem;
    }

    &-title {
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 2.8rem;
      margin-bottom: 2.1rem;
    }

    &-thead {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      color: #333333;

      &>img {
        display: block;
        height: 2rem;
        width: 2rem;
        margin-right: 0.8rem;
      }
    }

    &-percent {
      display: block;
      text-align: center;
    }
  }

  &__tabs {
    display: flex;
    margin-bottom: 1.4rem;
    flex-wrap: wrap;
    row-gap: 1rem;

    &-tab {
      height: 4.8rem;
      // max-height: 4.8rem;
      white-space: nowrap;
      max-width: 23%;
      width: 100%;
      border-radius: 6px;
      display: flex;
      align-items: center;
      background: rgba(214, 224, 242, 0.2);
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 1.9rem;
      // padding: 0 2rem;
      opacity: 0.5;
      padding: 1.5rem 1rem;
      margin-right: 1rem;
      cursor: pointer;
      transition: 0.3s ease-out;

      &:hover {
        box-shadow: 0 5px 10px rgba(158, 158, 158, 0.1);
      }

      &.active {
        opacity: 1;
        background: #D6E0F2;
        box-shadow: 0 5px 10px rgba(158, 158, 158, 0.1);
      }
      &.locale-usa {
        background: none;
        &.active {
          border: 2px solid var(--primary);
          background: #fff;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }

      &>img {
        display: block;
        height: 2.5rem;
        width: 3rem;
        margin-right: 0.8rem;
        object-fit: contain;
      }
    }
  }

  &__editable {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: var(--primary);
    margin-left: 2rem;

    img {
      margin-right: .5rem;
    }
  }

  &__content {
    background: #E9EDF3;
    border-radius: .8rem;
    margin-bottom: 1.4rem;

    &--red {
      background: #FFEDED;
    }
  }

  &__edit {
    color: var(--primary);
    cursor: pointer;
    margin-top: 2.5rem;
    display: inline-block;
    padding: 0 4.5rem 4.5rem 4.5rem;
  }

  &__errors {
    font-size: 1.2rem;
    color: $redLight;
  }

  &__buttons {
    display: flex;
    align-items: center;
    margin-top: 45px;
  }

  .btn {
    border: 1px solid var(--primary);
    box-sizing: border-box;
    border-radius: 4px;
    height: 45px;
    padding: 0 38px;
    margin-right: 20px;
    font-size: 16px;
    line-height: 19px;
    color: var(--primary);
    transition: 0.3s ease-out;

    &:hover {
      background-color: var(--primary);
      color: #fff;
    }

    &-save {
      background-color: var(--primary);;
      color: #fff;

      &:hover {
        background-color: transparent;
        color: var(--primary);
      }
    }
  }
}

@include below(769px) {
  .cashback-page__tabs{
    flex-wrap: wrap;
    margin-bottom: -1rem;
  }
  .cashback-page__tabs-tab{
    margin-right: 1rem;
    margin-bottom: 1rem;
    white-space: normal;
  }

  .cashback-page__content{
    margin-top: 1.4rem;
  }
}

@include below(500px) {
  .cashback-page__tabs-tab{

    font-size: 0;
    justify-content: center;
    img{
      height: 24px;
      width: auto;
    }
  }
}
</style>
