<template>
  <div class="selector multi-selector">
    <div class="selector__content" @click="showOptions = !showOptions">
      <div class="selector__title">{{ title }}</div>
      <div class="selector__value"> 
        <span v-if="selectedValues.length === options.length">{{ $t('multiselector.all') }}</span>
        <span v-else-if="!selectedValues.length">{{ placeholder }}</span>
        <div v-else class="selector__value-selected">
          {{ selectedValues.map((value) => value.name ).join(', ') }}
          <!-- <span v-for="user in selectedValues" :key="user.id">{{ user.name }}, </span> -->
        </div>
      </div>
      <div class="selector__arrow" :class="{'selector__arrow--open' : showOptions}">
        <img src="@/assets/icons/icon-arrow.svg" alt="arrow">
      </div>
    </div>
    <div v-if="showOptions" class="selector__options vk-selector">
      <div
        v-if="hasSearch"
        class="form-input selector__options-search"
        :class="
        [
          {'form-input--full' : search}
        ]"
      >
        <input v-model="search" type="text" required>
        <span class="form-input__label">
          {{ $t('search_props.placeholder') }}
        </span>
      </div>
      <div v-if="!search && hasSelectAll" class="selector__options-item">
        <div v-if="!selectedValues.length || selectedValues.length < options.length" @click="choseAll(true)">{{ $t('multiselector.choose_all') }}</div>
        <div v-else @click="choseAll(false)">{{ $t('multiselector.remove_all') }}</div>
      </div>
      <div
          v-for="option in filtredOptions"
          :key="option.id"
          class="selector__options-item"
          :class="{
            'selector__options-item--active': checkStatus(option.id),
          }"
          @click="chooseOption(option.id, option.name, option.value)"
      >
        <div>{{ option.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "@/utils/helpers"

export default {
  name: "MultiSelector",
  props: {
    title: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: null
    },
    selected: {
      type: [String, Array],
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    hasSearch: {
      type: Boolean,
      default: true
    },
    hasSelectAll: {
      type: Boolean,
      default: true
    },
    hasDebounce: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      selectedValues: [],
      showOptions: false,
      search: '',
    }
  },
  created() {
    this.debouncedEmitHandleChange = debounce(this.emitHandleChange, 1000)

    if (this.selected && this.selected.length) {
      this.selected === 'all' ?  this.choseAll(true) : this.selectedValues = JSON.parse(JSON.stringify(this.selected))
    }
  },
  computed: {
    filtredOptions() {
      if (this.search && this.options) {
        return this.options.filter(el => el.name && el.name.includes(this.search))
      } else if (this.options) {
        return this.options
      }
      return null
    }
  },
  methods: {
    checkStatus(id) {
      if(this.selectedValues) {
        const checked = this.selectedValues.findIndex(el => el.id === id)
        return checked === -1 ? false : true
      }
    },
    emitHandleChange() {
      this.$emit('handleChange', this.selectedValues)
    },
    chooseOption(id, name, value) {
      if (!this.selectedValues) { 
        this.selectedValues = [{ id , name, value }] 
      } else {
        const index = this.selectedValues.findIndex(el => el.id === id)
        if (index === -1) {
          this.selectedValues.push({ id , name, value })
        } else {
          this.removeOption(id)
        }
      }

      if (this.hasDebounce) {
        this.debouncedEmitHandleChange()
      } else {
        this.emitHandleChange()
      }
    },
    removeOption(id) {
      const index = this.selectedValues.findIndex(el => el.id === id)
      if (index !== -1) {
        this.selectedValues.splice(index, 1)
      }
    },
    choseAll(add) {
      this.selectedValues = []
      if (add) {
        this.options.forEach(el => {
          this.selectedValues.push(el)
        })
      } 
      this.$emit('handleChange', this.selectedValues)
    },
  },
}
</script>

<style scoped lang="scss">
  @import "../../assets/styles/helpers/variables";
  @import "../../assets/styles/helpers/mixin";

  .selector {
    &__arrow {
      position: absolute;
      right: 1rem;
      top: 2.2rem;
      
      img {
        transition: transform .3s;
      }

      &--open img {
        transform: rotate(-180deg);
      }
    }
    &__options-item {
      background: $basicWhite;
      color: initial;

      &--active {
        background: var(--primary);
        color: #fff;
      }
    }

    &__content {
      padding-right: 3rem;
    }

    &__value {
      overflow: hidden;

      &-selected {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;

        span {
          white-space: nowrap;
        }
      }
    } 
  }
</style>
